import PortalType from 'enums/PortalType';
import BorrowerAccountsApi from 'api/digifi/borrower-portal/BorrowerAccountsApi';
import IntermediaryAccountsApi from 'api/digifi/intermediary-portal/IntermediaryAccountsApi';
import BorrowerInvitesApi from 'api/digifi/borrower-portal/BorrowerInvitesApi';
import IntermediaryInvitesApi from 'api/digifi/intermediary-portal/IntermediaryInvitesApi';
import BorrowerSettingsApi from 'api/digifi/borrower-portal/BorrowerSettingsApi';
import IntermediarySettingsApi from 'api/digifi/intermediary-portal/IntermediarySettingsApi';
import ProductsRestApi from 'api/digifi/ProductsApi';
import { AppRoute } from 'enums/AppRoute';

const PORTAL_TYPE = process.env.REACT_APP_PORTAL_TYPE?.toLowerCase();

export const unauthorizeRedirectUrl = PORTAL_TYPE === PortalType.Borrower
  ? AppRoute.Home
  : AppRoute.SignIn;

export const noRedirectRoutes = PORTAL_TYPE === PortalType.Borrower
  ? [AppRoute.Home]
  : [];

export const getAccountsApi = () => {
  switch (PORTAL_TYPE) {
    case PortalType.Borrower:
      return new BorrowerAccountsApi(unauthorizeRedirectUrl, noRedirectRoutes);
    case PortalType.Intermediary:
      return new IntermediaryAccountsApi(unauthorizeRedirectUrl, noRedirectRoutes);
    default:
      return null as never;
  }
};

export const getInvitesApi = () => {
  switch (PORTAL_TYPE) {
    case PortalType.Borrower:
      return new BorrowerInvitesApi(unauthorizeRedirectUrl, noRedirectRoutes);
    case PortalType.Intermediary:
      return new IntermediaryInvitesApi(unauthorizeRedirectUrl, noRedirectRoutes);
    default:
      return null as never;
  }
};

export const getSettingsApi = () => {
  switch (PORTAL_TYPE) {
    case PortalType.Borrower:
      return new BorrowerSettingsApi(unauthorizeRedirectUrl, noRedirectRoutes);
    case PortalType.Intermediary:
      return new IntermediarySettingsApi(unauthorizeRedirectUrl, noRedirectRoutes);
    default:
      return null as never;
  }
};

export const getProductsApi = () => {
  if (!PORTAL_TYPE) {
    return null as never;
  }

  return new ProductsRestApi(unauthorizeRedirectUrl, PORTAL_TYPE as PortalType, noRedirectRoutes);
};
