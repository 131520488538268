import { portalLandingPageElementsApi } from 'store/api';
import { IPortalLandingPageElement } from 'api/digifi/PortalLandingPageElementsApi';
import { IGenerateImageUrlsParams } from 'api/digifi/portal-page-elements/BasePortalPageElementsApi';
import { apiSlice } from './apiSlice';

export const portalLandingPageElementsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortalLandingPageElements: builder.query<
      IPortalLandingPageElement[],
      void
    >({
      queryFn: async () => {
        try {
          const result = await portalLandingPageElementsApi.find({});

          return { data: result };
        } catch (error) {
          return { error };
        }
      },
    }),
    generateLandingPageImageUrls: builder.mutation<
      Record<string, string>,
      IGenerateImageUrlsParams
    >({
      queryFn: async (params) => {
        const result = await portalLandingPageElementsApi.generateImageUrls(params);

        return { data: result };
      },
    }),
  }),
});

export const {
  useGetPortalLandingPageElementsQuery,
  useGenerateLandingPageImageUrlsMutation,
} = portalLandingPageElementsSlice;
