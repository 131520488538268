import BaseApi, { FetchMethodType } from 'api/BaseApi';

export interface ICreateNewPasswordParams {
  password: string;
  resetPasswordToken: string;
}

export interface IResetPasswordApi {
  resetPassword(email: string): void;
  createNewPassword(params: ICreateNewPasswordParams): void;
}

export default class ResetPasswordRestApi extends BaseApi implements IResetPasswordApi {
  public resetPassword(email: string) {
    return this.fetch('/reset-password', {
      method: FetchMethodType.POST,
      body: {
        email,
      },
    });
  };

  public createNewPassword({ password, resetPasswordToken }: ICreateNewPasswordParams) {
    return this.fetch(`/reset-password/${resetPasswordToken}`, {
      method: FetchMethodType.PUT,
      body: {
        password,
      },
    });
  };
}
