import { IUpdatePasswordParams } from 'api/digifi/base/BaseAccountsApi';
import { ICreateNewPasswordParams } from 'api/digifi/ResetPasswordApi';
import BorrowerAccountsApi, {
  ICreateBorrowerAccountParams,
  ICurrentBorrowerAccountParams,
} from 'api/digifi/borrower-portal/BorrowerAccountsApi';
import IntermediaryAccountsApi, { ICurrentIntermediaryAccountParams } from 'api/digifi/intermediary-portal/IntermediaryAccountsApi';
import { accountsApi, emailVerificationApi, phoneVerificationApi, resetPasswordApi } from 'store/api';
import TokenUtils from 'utils/TokenUtils';
import { apiSlice, ApiSliceTagType } from './apiSlice';
import { createSafeQueryFn } from './utils/queries';
import { getItemInvalidationTag } from './utils/tags';

export const accountsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentAccount: builder.query<ICurrentBorrowerAccountParams | ICurrentIntermediaryAccountParams, void>({
      providesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async () => {
        return accountsApi.getCurrentAccount();
      }),
    }),
    updatePassword: builder.mutation<void, IUpdatePasswordParams>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (params) => {
        await accountsApi.updatePassword(params);
      }),
    }),
    sendUpdateEmailAddressCode: builder.mutation<void, string>({
      queryFn: createSafeQueryFn(async (email) => {
        await accountsApi.sendUpdateEmailAddressCode(email);
      }),
    }),
    updateEmailAddress: builder.mutation<void, string>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (code) => {
        await accountsApi.updateEmailAddress(code);
      }),
    }),
    sendUpdatePhoneNumberCode: builder.mutation<void, string>({
      queryFn: createSafeQueryFn(async (phone) => {
        await accountsApi.sendUpdatePhoneNumberCode(phone);
      }),
    }),
    updatePhoneNumber: builder.mutation<void, string>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (code) => {
        await accountsApi.updatePhoneNumber(code);
      }),
    }),
    createPasswordValidationToken: builder.mutation<void, string>({
      queryFn: createSafeQueryFn(async (password) => {
        const { passwordValidationToken } = await accountsApi.createPasswordValidationToken(password);
        TokenUtils.setTokens({ passwordValidationToken });
      }),
    }),
    sendPhoneVerificationCode: builder.mutation<void, void>({
      queryFn: createSafeQueryFn(async () => {
        await phoneVerificationApi.sendPhoneVerificationCode();
      }),
    }),
    verifyPhone: builder.mutation<void, string>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (code: string) => {
        await phoneVerificationApi.verifyPhone(code);
      }),
    }),
    sendEmailVerificationCode: builder.mutation<void, void>({
      queryFn: createSafeQueryFn(async () => {
        await emailVerificationApi.sendEmailVerificationCode();
      }),
    }),
    verifyEmail: builder.mutation<void, string>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (code: string) => {
        await emailVerificationApi.verifyEmail(code);
      }),
    }),
    resetPassword: builder.mutation<void, string>({
      queryFn: createSafeQueryFn(async (email: string) => {
        await resetPasswordApi.resetPassword(email);
      }),
    }),
    createNewPassword: builder.mutation<void, ICreateNewPasswordParams>({
      queryFn: createSafeQueryFn(async (params) => {
        await resetPasswordApi.createNewPassword(params);
      }),
    }),
    createBorrowerAccount: builder.mutation<void, ICreateBorrowerAccountParams>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (params) => {
        const { accessToken, refreshToken } = await (accountsApi as BorrowerAccountsApi).createAccount(params);

        TokenUtils.setTokens({ accessToken, refreshToken });
      }),
    }),
    updateFullName: builder.mutation<void, string>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (fullName) => {
        await (accountsApi as IntermediaryAccountsApi).updateFullName(fullName);
      }),
    }),
  }),
});

export const {
  useGetCurrentAccountQuery,
  useUpdatePasswordMutation,
  useSendUpdateEmailAddressCodeMutation,
  useUpdateEmailAddressMutation,
  useSendUpdatePhoneNumberCodeMutation,
  useUpdatePhoneNumberMutation,
  useCreatePasswordValidationTokenMutation,
  useSendPhoneVerificationCodeMutation,
  useVerifyPhoneMutation,
  useSendEmailVerificationCodeMutation,
  useVerifyEmailMutation,
  useResetPasswordMutation,
  useCreateNewPasswordMutation,
  useCreateBorrowerAccountMutation,
  useUpdateFullNameMutation,
} = accountsSlice;
