import BaseApi from 'api/BaseApi';

export enum BrandingLanguage {
  EN = 'en',
  DA = 'da',
  NL = 'nl',
  FI = 'fi',
  FR = 'fr',
  DE = 'de',
  HU = 'hu',
  IT = 'it',
  NB = 'nb',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  ES = 'es',
  SV = 'sv',
  TR = 'tr',
}

export interface IBranding {
  brandColor: string;
  accentColor: string;
  faviconId: string;
  logoId: string;
  companyName: string;
  country: string;
  language: BrandingLanguage;
}

export interface ILegalDocument {
  id: string;
  name: string;
  body: string;
  showInFooter: boolean;
  showOnSubmitApplication: boolean;
  updatedAt: string;
}

export interface IVariablesSettings {
  phoneNumberFormat: string;
  currency: string;
}

export interface ICustomCssConfigParams {
  stylesheet: string | null;
}

export interface IBasePortalSettings {
  branding: IBranding;
  customCssConfig: ICustomCssConfigParams;
  legalConsents: ILegalDocument[];
  variablesSettings: IVariablesSettings;
  customFooter: string | null;
}

export interface IBaseSettingsApi<IPortalSettings> {
  getPortalSettings(): Promise<IPortalSettings>;
}

export default abstract class BaseSettingsApi<PortalSettings = IBasePortalSettings>
  extends BaseApi
  implements IBaseSettingsApi<PortalSettings>
{
  protected resourceName = 'settings';

  public getPortalSettings(): Promise<PortalSettings> {
    return this.fetch(`/${this.resourceName}`);
  }
}
