import { settingsApi } from 'store/api';
import { IBorrowerPortalSettings } from 'api/digifi/borrower-portal/BorrowerSettingsApi';
import { IIntermediaryPortalSettings } from 'api/digifi/intermediary-portal/IntermediarySettingsApi';
import { apiSlice } from './apiSlice';
import { createSafeQueryFn } from './utils/queries';

export const portalSettingsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortalSettings: builder.query<
      IBorrowerPortalSettings | IIntermediaryPortalSettings,
      void
    >({
      queryFn: createSafeQueryFn(async () => {
        return settingsApi.getPortalSettings();
      }),
    }),
  }),
});

export const { useGetPortalSettingsQuery, useLazyGetPortalSettingsQuery } = portalSettingsSlice;
