import { FC } from 'react';
import clsx from 'clsx';
import Spinner, { SpinnerProps } from 'product_modules/components/Spinner';
import styles from './Spinner.module.scss';

const SpinnerWrapper: FC<SpinnerProps> = (props) => {
  return (
    <Spinner {...props} className={clsx(styles.spinner, props.className)} />
  );
};

export default SpinnerWrapper;
