import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'handlers';
import { apiSlice } from 'slices';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false, // Temp to remove lot's of errors from console, we will need to review redux states
  }).concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
