import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryError } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

export const createSafeQueryFn = <Args extends unknown[], ReturnType>(
  query: (...args: Args) => Promise<ReturnType>,
) => {
  return async (...args: Args): Promise<
    QueryReturnValue<
      ReturnType,
      BaseQueryError<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>>
    >
  > => {
    try {
      const result = await query(...args);

      return { data: result as ReturnType };
    } catch (error) {
      return { error };
    }
  };
};
