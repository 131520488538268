import React, { forwardRef, ReactElement } from 'react';
import clsx from 'clsx';
import {
  DownloadImage,
  AddImage,
  ShareImageCustomColor,
  MoveBackImage,
  EditImage,
  LeftArrowImage,
  ChevronIcon,
  UploadImage,
  ExpandIcon,
  CollapseIcon,
  RightArrowImage,
} from 'product_modules/static/images';
import styles from './ButtonWithImage.module.scss';

export type ButtonWithImageKind =
  | 'download'
  | 'add'
  | 'link'
  | 'back'
  | 'edit'
  | 'leftArrow'
  | 'rightArrow'
  | 'topArrow'
  | 'upload'
  | 'expand'
  | 'collapse';

export interface ButtonWithImageProps {
  title: ReactElement | string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  kind?: ButtonWithImageKind;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  reverse?: boolean;
  titleClassName?: string;
  imageClassName?: string;
}

// eslint-disable-next-line consistent-return
const getButtonImage = (kind: ButtonWithImageKind, imageClassName?: string): ReactElement => {
  // eslint-disable-next-line default-case
  switch (kind) {
    case 'download':
      return <DownloadImage className={clsx(styles.downloadImage, imageClassName)} />;
    case 'add':
      return <AddImage className={clsx(styles.addImage, imageClassName)} />;
    case 'link':
      return <ShareImageCustomColor className={clsx(styles.shareImage, imageClassName)} />;
    case 'back':
      return <MoveBackImage className={clsx(styles.imageStyles, imageClassName)} />;
    case 'edit':
      return <EditImage className={clsx(styles.editImage, imageClassName)} />;
    case 'leftArrow':
      return <LeftArrowImage className={clsx(styles.leftArrowImage, imageClassName)} />;
    case 'rightArrow':
      return <RightArrowImage className={clsx(styles.rightArrowImage, imageClassName)} />;
    case 'topArrow':
      return <ChevronIcon className={clsx(styles.chevronIcon, imageClassName)} />;
    case 'upload':
      return <UploadImage className={clsx(styles.uploadImage, imageClassName)} />
    case 'expand':
      return <ExpandIcon className={clsx(styles.expandImage, imageClassName)} />
    case 'collapse':
      return <CollapseIcon className={clsx(styles.collapseImage, imageClassName)} />
  }
};

const ButtonWithImage = forwardRef<HTMLButtonElement, ButtonWithImageProps>(
  ({
   reverse,
   title,
   onClick,
   kind = 'download',
   className,
   disabled,
   tabIndex,
   titleClassName,
   imageClassName,
   ...rest
 }, ref) => (
    <button
      disabled={disabled}
      ref={ref}
      type="button"
      onClick={onClick}
      className={clsx(styles.buttonWithImage, className, disabled && styles.disabled, reverse && styles.reversedButton)}
      tabIndex={tabIndex}
      {...rest}
    >
      {getButtonImage(kind, imageClassName)}
      {titleClassName ? (
        <span className={titleClassName}>{title}</span>
      ) : title}
    </button>
  ),
);

export default ButtonWithImage;
