import { FC, useEffect } from 'react';
import TranslationService from 'product_modules/services/TranslationService';

const LocalizationProvider: FC = ({ children }) => {
  useEffect(() => {
    TranslationService.initialize();
  }, []);

  return <>{children}</>;
};

export default LocalizationProvider;
