import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { taskDetailsApi, tasksApi } from 'store/api';
import { IFindTaskParams, ITask } from 'api/digifi/TasksApi';
import { ISubmitTaskParams } from 'api/digifi/TaskDetailsApi';
import { RootState } from 'store';
import rejectWithValue from 'utils/rejectWithValue';

enum TasksActionType {
  FindTasks = 'tasks/findTasks',
  SubmitTask = 'tasks/submitTask',
}

const tasksAdapter = createEntityAdapter<ITask>();

export interface ITasksState extends EntityState<ITask> {}

const initialState: ITasksState = tasksAdapter.getInitialState();

export const findApplicationTasks = createAsyncThunk(
  TasksActionType.FindTasks,
  async (params: IFindTaskParams, thunkApi) => {
    try {
      return await tasksApi.find(params);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const submitTask = createAsyncThunk(
  TasksActionType.SubmitTask,
  async (params: ISubmitTaskParams, thunkApi) => {
    try {
      return await taskDetailsApi.submit(params);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

const tasksSlice = createSlice({
  name: 'tasksSlice',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(findApplicationTasks.fulfilled, (state, { payload }) => {
        tasksAdapter.setAll(state, payload);
      })
      .addCase(submitTask.fulfilled, (state, { payload }) => {
        tasksAdapter.upsertOne(state, payload);
      });
  },
});

export const { selectEntities: selectTaskEntities } = tasksAdapter.getSelectors((state: RootState) => state.tasks);

export default tasksSlice.reducer;
