import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { googleAnalyticsApi } from 'store/api';
import DigitalLendingPortalEnvironment from 'enums/DigitalLendingPortalEnvironment';
import { useGetPortalEnvironmentQuery } from 'slices';

const GoogleAnalyticsContext = createContext({});

const GoogleAnalyticsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const { currentData: portalEnvironment } = useGetPortalEnvironmentQuery();

  const initializeAnalytics = useCallback(async () => {
    const { measurementId } = await googleAnalyticsApi.getMeasurementId();

    if (measurementId) {
      ReactGA.initialize(measurementId);
      setIsInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (portalEnvironment === DigitalLendingPortalEnvironment.Production) {
      initializeAnalytics();
    }
  }, [initializeAnalytics, portalEnvironment]);

  return (
    <GoogleAnalyticsContext.Provider value={{ isInitialized }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export default GoogleAnalyticsProvider;
