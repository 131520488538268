import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { IBasePortalPageElement, PortalPageElementType } from './BasePortalPageElement';

export interface IGenerateImageUrlsParams {
  imageElementIds: string[];
}

export interface IPortalPageElementsApi<
  ElementModel,
  FindParams = {},
> {
  find(params: FindParams): Promise<ElementModel[]>;
  generateImageUrls(params: IGenerateImageUrlsParams): Promise<Record<string, string>>;
}

export default abstract class BasePortalPageElementsRestApi<
  ElementType extends PortalPageElementType = PortalPageElementType,
  ElementModel extends IBasePortalPageElement<ElementType> = IBasePortalPageElement<ElementType>,
  FindParams extends {} = {},
>
  extends BaseApi implements IPortalPageElementsApi<
    ElementModel,
    FindParams
  > {
  protected abstract resourceName: string;

  public find(params: FindParams) {
    const urlSearchParams = this.getFindQuery(params);

    return this.fetch<ElementModel[]>(`/${this.resourceName}?${urlSearchParams}`);
  }

  public async generateImageUrls(params: IGenerateImageUrlsParams) {
    return this.fetch<Record<string, string>>(`/${this.resourceName}/urls`, {
      method: FetchMethodType.POST,
      body: params,
    });
  }

  protected getFindQuery(params: FindParams) {
    return new URLSearchParams(params);
  };
}
