import { createSlice, PayloadAction, Dispatch, createEntityAdapter } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { RootState } from 'store';
import { NotificationType } from 'components/Notifications/NotificationList';

const DEFAULT_NOTIFICATION_DURATION = 4000;

export enum NotificationComponentTypes {
  CreateAccountComponent = 'AccountAlreadyExistsErrorNotification',
}

export type ComponentTypeWithProps<ComponentType, Props> = {
  componentType: ComponentType;
  props?: Props;
};

export type NotificationComponents = ComponentTypeWithProps<
  NotificationComponentTypes.CreateAccountComponent,
  null
>;

export interface INotificationProps {
  type: NotificationType;
  notification: string | NotificationComponents;
  timeout?: number;
  id: string;
}

interface ICreateNotificationProps {
  type: NotificationType;
  notification: string | NotificationComponents;
  dispatch: Dispatch,
  timeout?: number,
}

const notificationsAdapter = createEntityAdapter<INotificationProps>();

const initialState = notificationsAdapter.getInitialState();

export const createNotification = ({ type, notification, dispatch, timeout = DEFAULT_NOTIFICATION_DURATION }: ICreateNotificationProps) => {
  const id = nanoid();
  removeNotificationTimer(id, timeout, dispatch);
  dispatch(showNotification({ type, notification, timeout, id }));
};

const removeNotificationTimer = (id: string, timeout: number, dispatch: Dispatch) => {
  if (timeout) {
    const t = setTimeout(() => {
      dispatch(hideNotification(id));
      clearTimeout(t);
    }, timeout);
  }
};

const authWorkflowStepsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification(state, { payload }: PayloadAction<INotificationProps>) {
      notificationsAdapter.setOne(state, payload);
    },
    hideNotification(state, { payload }: PayloadAction<string>) {
      notificationsAdapter.removeOne(state, payload);
    },
  },
});

export const { showNotification, hideNotification } = authWorkflowStepsSlice.actions;

export const { selectAll: selectAllNotifications } = notificationsAdapter.getSelectors((state: RootState) => state.notifications);

export default authWorkflowStepsSlice.reducer;

