const normalizeEntityArray = <
  Entity extends Record<string, any>
>(array: Array<Entity>, field: string = 'id') => {
  return array.reduce((entitiesById, entity) => {
    entitiesById[`${entity[field]}`] = entity;

    return entitiesById;
  }, {} as Record<string, Entity>);
};

export default normalizeEntityArray;
