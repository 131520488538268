export default class PromiseQueue {
  private isRunning: boolean = false;

  private readonly queue: Array<() => Promise<unknown>> = [];

  constructor(
    private maxLength: number = Infinity,
  ) {}

  public enqueue(task: () => Promise<unknown>) {
    if (this.queue.length >= this.maxLength) {
      return;
    }

    this.queue.push(task);

    this.run();
  }

  public async run() {
    if (this.isRunning || this.queue.length === 0) {
      return;
    }

    this.isRunning = true;

    const task = this.queue.shift();

    try {
      await task?.();
    } catch (err) {
      console.error('Task failed:', err);
    } finally {
      this.isRunning = false;

      this.run();
    }
  }
}
