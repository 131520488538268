import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { stringifyRequestBody } from 'utils/fetch';

export interface ICreateLinkTokenResponse {
  token: string;
}

export interface IGetStatusResponse {
  isConnected: boolean;
}

export enum PlaidProduct {
  Auth = 'auth',
  Identity = 'identity',
  Transactions = 'transactions',
  Investments = 'investments',
  Liabilities = 'liabilities',
  Assets = 'assets',
  Income = 'income_verification',
}

export enum PlaidIncomeSourceType {
  Payroll = 'payroll',
  Bank = 'bank',
}

export interface ICreateFinancialLinkTokenParams {
  products: PlaidProduct[]
  linkCustomizationName?: string;
}

export interface ICreateIncomeLinkTokenParams extends ICreateFinancialLinkTokenParams{
  incomeSourceType?: PlaidIncomeSourceType;
  daysRequested?: string;
}

export interface ICreateAccessTokenParams {
  publicToken: string;
}

export interface IPlaidApi {
  createIncomeLinkToken(params: ICreateIncomeLinkTokenParams): Promise<ICreateLinkTokenResponse>;
  createFinancialLinkToken(params: ICreateFinancialLinkTokenParams): Promise<ICreateLinkTokenResponse>;
  createAccessToken(params: ICreateAccessTokenParams): Promise<void>;
}

export default class PlaidRestApi extends BaseApi implements IPlaidApi {
  public createIncomeLinkToken(params: ICreateIncomeLinkTokenParams): Promise<ICreateLinkTokenResponse> {
    return this.fetch<ICreateLinkTokenResponse>('/plaid/tokens/link/income', {
      method: FetchMethodType.POST,
      body: stringifyRequestBody(params),
    });
  }

  public createFinancialLinkToken(params: ICreateFinancialLinkTokenParams): Promise<ICreateLinkTokenResponse> {
    return this.fetch<ICreateLinkTokenResponse>('/plaid/tokens/link/financial', {
      method: FetchMethodType.POST,
      body: stringifyRequestBody(params),
    });
  }

  public async createAccessToken(params: ICreateAccessTokenParams): Promise<void> {
    await this.fetch<void>('/plaid/tokens/access', {
      method: FetchMethodType.POST,
      body: stringifyRequestBody(params),
    });
  }

  public async getStatus(): Promise<IGetStatusResponse> {
    return this.fetch<IGetStatusResponse>('/plaid/status');
  }
}
