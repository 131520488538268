import BaseApi, { FetchMethodType } from 'api/BaseApi';
import TokenUtils from 'utils/TokenUtils';
import { RequestError } from 'errors/RequestError';
import { IAuthResponse } from 'api/digifi/types';
import { HttpStatusCode } from 'enums/HttpStatusCode';

export interface ISignInParams {
  email: string;
  password: string;
}

export interface ISessionsApi {
  signIn(params: ISignInParams): Promise<IAuthResponse>;
  logout(): Promise<void>;
  validateToken(): Promise<void>;
}

export default class SessionsRestApi extends BaseApi implements ISessionsApi {
  public signIn({ email, password }: ISignInParams): Promise<IAuthResponse> {
    return this.fetch('/sessions', {
      method: FetchMethodType.POST,
      body: {
        email,
        password,
      },
    });
  };

  public logout(): Promise<void> {
    return this.fetch('/sessions', {
      method: FetchMethodType.DELETE,
    });
  }

  public validateToken(): Promise<void> {
    if (!TokenUtils.getAccessToken()) {
      throw new RequestError('No refresh token was found', HttpStatusCode.BadRequest);
    }

    return this.fetch('/sessions/token-status', {
      method: FetchMethodType.PUT,
    });
  }
}
