import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILegalDocument } from 'api/digifi/base/BaseSettingsApi';

export const DEFAULT_PHONE_NUMBER_FORMAT = 'US';

export interface ISettingsState {
  selectedDocument: ILegalDocument | null;
}

const initialState: ISettingsState = {
  selectedDocument: null,
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setSelectedDocument(state, { payload }: PayloadAction<ILegalDocument | null>) {
      state.selectedDocument = payload;
    },
  },
});

export const { setSelectedDocument } = settingsSlice.actions;

export default settingsSlice.reducer;
