import BaseApi, { FetchMethodType } from 'api/BaseApi';

export interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IBaseInviteInfo {
  email: string;
  phone: string;
}

export interface IBaseAcceptInviteParams {
  email: string;
  phoneNumber: string;
  password: string;
}

export interface IBaseInvitesApi {
  acceptInvite(body: IBaseAcceptInviteParams, token: string): Promise<IAuthResponse>;
}

export default abstract class BaseInvitesRestApi extends BaseApi implements IBaseInvitesApi {
  protected resourceName = 'invites';

  public acceptInvite(body: IBaseAcceptInviteParams, token: string): Promise<IAuthResponse> {
    return this.fetch(`/${this.resourceName}/${token}`, {
      method: FetchMethodType.POST,
      body,
    });
  };
}
