import { portalPageElementsApi } from 'store/api';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';
import { IGenerateImageUrlsParams } from 'api/digifi/portal-page-elements/BasePortalPageElementsApi';
import { apiSlice } from './apiSlice';

export const portalPageElementsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortalPageElements: builder.query<
      IPortalPageElement[],
      string
    >({
      queryFn: async (statusId) => {
        try {
          const result = await portalPageElementsApi.find({ statusId });

          return { data: result };
        } catch (error) {
          return { error };
        }
      },
    }),
    generatePortalPageImageUrls: builder.mutation<
      Record<string, string>,
      IGenerateImageUrlsParams
    >({
      queryFn: async (params) => {
        const result = await portalPageElementsApi.generateImageUrls(params);

        return { data: result };
      },
    }),
  }),
});

export const {
  useGetPortalPageElementsQuery,
  useGeneratePortalPageImageUrlsMutation,
} = portalPageElementsSlice;
