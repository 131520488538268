import { createAction, createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';

export interface IEntitiesSliceState {
  unknownEntities: Record<string, boolean>;
}

export interface IEntitiesSliceParams<State extends IEntitiesSliceState> {
  shouldMarkEntityAsUnknown: (field: string | number, state: Draft<State>) => boolean;
}

export const entitiesSliceInitialState: IEntitiesSliceState = {
  unknownEntities: {},
};

const createEntitiesSlice = <State extends IEntitiesSliceState>(
  entityName: string,
  initialState: State,
  builderCallback: (builder: ActionReducerMapBuilder<State>) => void,
  { shouldMarkEntityAsUnknown }: IEntitiesSliceParams<State>,
) => {
  const recomputeUnknownEntitiesFor = createAction<Array<string | number>>(`${entityName}/markUnknownEntities`);

  const reducer = createReducer<State & IEntitiesSliceState>(initialState, (builder) => {
    builder.addCase(recomputeUnknownEntitiesFor, (state, action) => {
      action.payload.forEach((field) => {
        if (shouldMarkEntityAsUnknown(field, state)) {
          state.unknownEntities[field] = true;
        }
      });
    });

    builderCallback(builder);
  });

  return {
    reducer,
    actions: {
      recomputeUnknownEntitiesFor,
    },
  };
};

export default createEntitiesSlice;
