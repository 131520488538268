import { DataViewSortingType, DataFilter } from 'product_modules/api/LoanOriginationSystem/Types';
import { ISystemVariableValue, UserInfo } from 'product_modules/api/Types';
import { CountryCode } from 'libphonenumber-js';
import { IdentificationType } from 'product_modules/components/IdentificationNumberInput/types';
import { VisualDataType } from 'product_modules/enums/VisualDataType';
import { DateRange } from 'product_modules/utils/dateFormat';

export type PermissionGroupId = string;

export enum VariableSortingField {
  DisplayName = 'name',
  SystemName = 'systemName',
  VisualDataType = 'visualDataType',
  VariableType = 'isStandard',
  UpdatedAt = 'updatedAt',
}

export enum VariableDataType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
  Address = 'Address',
  Table = 'Table',
}

export enum VariableType {
  Custom = 'custom',
  Standard = 'standard',
}

export enum VariableAccessPermissionType {
  ReadWrite = 'ReadWrite',
  ReadOnly = 'ReadOnly',
  PartialRead = 'PartialRead',
  NoAccess = 'NoAccess',
}

export type VariableAccessPermissions = Record<PermissionGroupId, VariableAccessPermissionType>;

export type CapVisualAttributes = {
  maxAllowedValue?: string;
  minAllowedValue?: string;
}

export interface ListVisualDataTypeAttributes {
  optionsList: string[];
}

export interface DateVisualAttributes {
  dateFormat: string;
}

export interface IdentificationTypeVisualAttributes {
  identificationNumberType: IdentificationType;
  identificationNumberDescription?: string;
}

export interface PhoneNumberVisualAttributes {
  phoneNumberFormat: CountryCode;
  phoneNumberWithFlag?: boolean;
}

export interface MonetaryVisualAttributes extends CapVisualAttributes {
  currency: string;
}

export interface TableColumn {
  id: string;
  name: string;
  systemName: string;
  dataType: VariableDataType;
  visualDataType: VisualDataType;
  visualAttributes: VariableVisualAttributes;
  required?: boolean;
}

export interface TableVisualAttributes {
  columns: TableColumn[];
}

export type VariableVisualAttributes = DateVisualAttributes
  | IdentificationTypeVisualAttributes
  | PhoneNumberVisualAttributes
  | ListVisualDataTypeAttributes
  | MonetaryVisualAttributes
  | TableVisualAttributes
  | CapVisualAttributes;

export interface BasicVariable {
  id: string;
  name: string;
  systemName: string;
  organizationId: string;
  organizationVersion: number | null;
  isArchived: boolean;
  dataType: VariableDataType;
  visualDataType: VisualDataType;
}

export interface Variable extends BasicVariable {
  permissions: VariableAccessPermissions;
  visualAttributes: VariableVisualAttributes;
  isStandard: boolean;
  dependsOn?: string | null;
  description?: string;
  createdBy?: UserInfo | null;
  updatedBy?: UserInfo | null;
  updatedAt: Date;
  createdAt: Date;
}

export interface ISystemVariable {
  id: string;
  name: string;
  format?: (value: ISystemVariableValue) => string;
}

export type VariableValidationAndFormattingFields = {
  visualDataType: VisualDataType;
  visualAttributes: VariableVisualAttributes;
}

export interface VariablesDataFilters extends DataFilter {
  ids?: string[];
  offset?: number;
  count?: number;
  search?: string;
  dueCreatedDateRange?: DateRange;
  dueUpdatedDateRange?: DateRange;
  type?: VariableType | null;
  dataType?: VariableDataType | VariableDataType[];
  excludeDataTypes?: VariableDataType[];
  visualDataType?: VisualDataType | null;
  systemNames?: string[];
  teamMembers?: string[] | null;
  showArchived?: boolean;
}

export type VariableSortingType = DataViewSortingType<VariableSortingField>;
