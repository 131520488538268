import React from 'react';
import CountryProvider from 'product_modules/providers/CountryProvider';
import { useGetPortalSettingsQuery } from 'slices';

const SettingsCountryProvider: React.FC = ({ children }) => {
  const { currentData: settings } = useGetPortalSettingsQuery();

  return (
    <CountryProvider value={settings?.branding.country}>
      {children}
    </CountryProvider>
  );
};

export default SettingsCountryProvider;
