import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export enum ApiSliceTagType {
  Account = 'account',
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({}),
  endpoints: () => ({}),
  tagTypes: Object.values(ApiSliceTagType),
});
