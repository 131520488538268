import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IPlaidState {
  isPlaidConnected: boolean;
  isPlaidElementExists: boolean;
}

const initialState: IPlaidState = {
  isPlaidConnected: false,
  isPlaidElementExists: false,
};

export const setIsPlaidConnected = createAction('plaid/setIsPlaidConnected');
export const setIsPlaidElementExists = createAction<boolean>('plaid/setIsPlaidElementExists');

const plaidSlice = createSlice({
  name: 'plaid',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setIsPlaidConnected, (state) => {
        state.isPlaidConnected = true;
      })

      .addCase(setIsPlaidElementExists, (state, action) => {
        state.isPlaidElementExists = action.payload;
      });
  },
});

export default plaidSlice.reducer;
