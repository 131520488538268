import ProductCalculationsRestApi from 'api/digifi/ProductCalculationsApi';
import GooglePlacesRestApi from 'api/digifi/GooglePlacesApi';
import VariablesRestApi from 'api/digifi/VariablesApi';
import LayoutConfigurationRestApi from 'api/digifi/layout/LayoutConfigurationApi';
import ApplicationsRestApi from 'api/digifi/ApplicationsApi';
import AdminAuthApi from 'api/digifi/AdminAuthApi';
import PortalPageElementsRestApi from 'api/digifi/PortalPageElementsApi';
import TasksRestApi from 'api/digifi/TasksApi';
import AutomationWorkflowApi from 'api/digifi/AutomationWorkflowApi';
import EnvironmentApi from 'api/EnvironmentApi';
import TasksDetailsRestApi from 'api/digifi/TaskDetailsApi';
import PortalPageInfoRestApi from 'api/digifi/PortalPageInfoApi';
import DocuSignRestApi from 'api/digifi/DocuSignApi';
import GoogleAnalyticsRestApi from 'api/digifi/GoogleAnalyticsApi';
import PortalLandingPageElementsRestApi from 'api/digifi/PortalLandingPageElementsApi';
import PlaidRestApi from 'api/digifi/PlaidApi';
import SessionsRestApi from 'api/digifi/SessionsApi';
import PhoneVerificationRestApi from 'api/digifi/PhoneVerificationApi';
import EmailVerificationRestApi from 'api/digifi/EmailVerificationApi';
import ResetPasswordRestApi from 'api/digifi/ResetPasswordApi';
import { getAccountsApi, getInvitesApi, getSettingsApi, noRedirectRoutes, unauthorizeRedirectUrl } from './apiBuilders';
import BorrowersRestApi from 'api/digifi/BorrowersApi';
import LoggerApi from 'api/LoggerApi';
import DocumentsRestApi from 'api/digifi/DocumentsApi';

export const productCalculationsApi = new ProductCalculationsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const googlePlacesApi = new GooglePlacesRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const variablesApi = new VariablesRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const layoutConfigurationApi = new LayoutConfigurationRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const adminAuthApi = new AdminAuthApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const applicationsApi = new ApplicationsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const portalPageElementsApi = new PortalPageElementsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const tasksApi = new TasksRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const automationWorkflowApi = new AutomationWorkflowApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const environmentApi = new EnvironmentApi();
export const taskDetailsApi = new TasksDetailsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const portalPageInfoApi = new PortalPageInfoRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const docuSignApi = new DocuSignRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const googleAnalyticsApi = new GoogleAnalyticsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const plaidApi = new PlaidRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const portalLandingPageElementsApi = new PortalLandingPageElementsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const borrowersApi = new BorrowersRestApi(unauthorizeRedirectUrl, noRedirectRoutes);

export const accountsApi = getAccountsApi();
export const invitesApi = getInvitesApi();
export const settingsApi = getSettingsApi();
export const sessionsApi = new SessionsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const phoneVerificationApi = new PhoneVerificationRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const emailVerificationApi = new EmailVerificationRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const resetPasswordApi = new ResetPasswordRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const loggerApi = new LoggerApi(unauthorizeRedirectUrl, noRedirectRoutes);
export const documentsApi = new DocumentsRestApi(unauthorizeRedirectUrl, noRedirectRoutes);
