import { BorrowerType } from 'product_modules/enums/BorrowerType';
import BaseSettingsApi, { IBasePortalSettings } from 'api/digifi/base/BaseSettingsApi';

export const DEFAULT_BORROWER_TYPES = [BorrowerType.Person, BorrowerType.Company];

interface IPortalConfiguration {
  borrowerLockPeriodDays: number;
}

export interface IBorrowerPortalSettings extends IBasePortalSettings {
  portalConfiguration: IPortalConfiguration;
  borrowerTypes: BorrowerType[];
}

export default class BorrowerSettingsApi extends BaseSettingsApi<IBorrowerPortalSettings> {}
