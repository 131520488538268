import BaseAccountsRestApi, { IBaseAccountParams, IBaseAccountsApi } from 'api/digifi/base/BaseAccountsApi';
import { FetchMethodType } from 'api/BaseApi';

export interface ICurrentIntermediaryAccountParams extends IBaseAccountParams {
  intermediaryId: string;
}

export interface IIntermediaryAccountsApi extends IBaseAccountsApi {
  getCurrentAccount(): Promise<ICurrentIntermediaryAccountParams>;
  updateFullName(fullName: string): Promise<ICurrentIntermediaryAccountParams>;
}

export default class IntermediaryAccountsApi extends BaseAccountsRestApi implements IIntermediaryAccountsApi {
  public getCurrentAccount(): Promise<ICurrentIntermediaryAccountParams> {
    return this.fetch(`/${this.resourceName}`);
  }

  public updateFullName(fullName: string): Promise<ICurrentIntermediaryAccountParams> {
    return this.fetch(`/${this.resourceName}/full-name`, {
      method: FetchMethodType.PUT,
      body: {
        fullName,
      },
    });
  }
}
