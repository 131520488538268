import { createAction, createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { ApplicationSortingField, ICreateApplicationParams, IApplicationViewModel, ICreateApplicationByIntermediaryParams } from 'api/digifi/ApplicationsApi';
import { ISortType } from 'types/SortType';
import { applicationsApi } from 'store/api';
import rejectWithValue from 'utils/rejectWithValue';
import { getCurrentApplication } from './activeBorrowerInformationSlice';

enum ApplicationsActionType {
  CreateApplication = 'applications/createApplication',
  createApplicationByIntermediary = 'applications/createApplicationByIntermediary',
  GetBorrowerApplications = 'applications/getBorrowerApplications',
  FindByDisplayId = 'applications/findByDisplayId',
  ChangeSearch = 'applications/changeSearch',
  ChangeFilters = 'applications/changeFilters',
}

export interface IGetBorrowerApplicationParams {
  offset: number;
  count: number;
  sortType: ISortType<ApplicationSortingField>;
  productId?: string;
  search?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  onlyInFinalStatus?: boolean;
  onlyInProgress?: boolean;
  onlyInApprovedStatus?: boolean;
  onlyInRejectedStatus?: boolean;
}

interface IIntermediaryApplicationsFilters {
  createdAtFrom?: string;
  createdAtTo?: string;
  productId?: string;
}

const applicationsAdapter = createEntityAdapter<IApplicationViewModel>();

export type IApplicationsState = EntityState<IApplicationViewModel> & {
  filters: IIntermediaryApplicationsFilters;
  search: string;
};

const initialState: IApplicationsState = { ...applicationsAdapter.getInitialState(), search: '', filters: {} };

export const createApplication = createAsyncThunk(
  ApplicationsActionType.CreateApplication,
  async (params: ICreateApplicationParams, thunkApi) => {
    try {
      return await applicationsApi.createApplication(params);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const createApplicationByIntermediary = createAsyncThunk(
  ApplicationsActionType.createApplicationByIntermediary,
  async (params: ICreateApplicationByIntermediaryParams, thunkApi) => {
    try {
      return await applicationsApi.createApplicationByIntermediary(params);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const getBorrowerApplications = createAsyncThunk(
  ApplicationsActionType.GetBorrowerApplications,
  async (params: IGetBorrowerApplicationParams, thunkApi) => {
    try {
      const applications = await applicationsApi.getBorrowerApplications({
        count: params.count,
        offset: params.offset,
        sortField: params.sortType.field,
        sortDirection: params.sortType.ascending ? 'asc' : 'desc',
        search: params.search,
        onlyInFinalStatus: params.onlyInFinalStatus,
        onlyInProgress: params.onlyInProgress,
        onlyInApprovedStatus: params.onlyInApprovedStatus,
        onlyInRejectedStatus: params.onlyInRejectedStatus,
        createdAtFrom: params.createdAtFrom,
        createdAtTo: params.createdAtTo,
        productId: params.productId,
      });

      return {
        items: applications.applications,
        total: applications.total,
      };
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const findApplicationByDisplayId = createAsyncThunk(
  ApplicationsActionType.FindByDisplayId,
  async (displayId: string, thunkApi) => {
    try {
      return await applicationsApi.findByDisplayId(displayId);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const changeSearch = createAction<string>(ApplicationsActionType.ChangeSearch);

export const changeFilters = createAction<IIntermediaryApplicationsFilters>(ApplicationsActionType.ChangeFilters);

const applicationsSlice = createSlice({
  name: 'applicationsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createApplication.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state, payload);
      })
      .addCase(createApplicationByIntermediary.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state, payload);
      })
      .addCase(getCurrentApplication.fulfilled, (state, { payload }) => {
        if (payload) {
          applicationsAdapter.setOne(state, payload);
        }
      })
      .addCase(getBorrowerApplications.fulfilled, (state, { payload }) => {
        applicationsAdapter.addMany(state, payload.items);
      })
      .addCase(findApplicationByDisplayId.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state, payload);
      })
      .addCase(changeSearch, (state, { payload }) => {
        state.search = payload;
      })
      .addCase(changeFilters, (state, { payload }) => {
        state.filters = payload;
      });
  },
});

export default applicationsSlice.reducer;
