import { HttpStatusCode } from 'enums/HttpStatusCode';
import { RequestError } from 'errors/RequestError';
import TranslationService from 'product_modules/services/TranslationService';
import { ErrorCode } from 'types/ErrorCode';

const getApiErrorMessages = (code: ErrorCode, values: Partial<Record<string, string | number>>) =>
  ({
    [ErrorCode.ApplicationPermissionsVerificationFailure]: TranslationService.translate(
      'toastMessages.unableToVerifyPermissions',
    ),
    [ErrorCode.ModifyApplicationInStatusPermissionDenied]: TranslationService.translate(
      'toastMessages.noPermissionToModifyApplication',
    ),
    [ErrorCode.EditApplicationPermissionDenied]: TranslationService.translate(
      'toastMessages.noPermissionToEditApplication',
    ),
    [ErrorCode.AuthorNotFound]: TranslationService.translate('toastMessages.authorNotFound'),
    [ErrorCode.PasswordValidationTokenMissing]: TranslationService.translate('toastMessages.passwordTokenMissing'),
    [ErrorCode.TokenInvalidOrAccountDeleted]: TranslationService.translate(
      'toastMessages.tokenInvalidOrAccountDeleted',
    ),
    [ErrorCode.PasswordValidationTokenInvalid]: TranslationService.translate('toastMessages.passwordTokenInvalid'),
    [ErrorCode.EmailAlreadyInUse]: TranslationService.translate('toastMessages.emailAlreadyUsed'),
    [ErrorCode.AuthTokenInvalid]: TranslationService.translate('toastMessages.authTokenInvalid'),
    [ErrorCode.AccountMissingPhoneNumber]: TranslationService.translate('toastMessages.accountMissingPhoneNumber'),
    [ErrorCode.PhoneNumberNotAssociatedWithAccount]: TranslationService.translate(
      'toastMessages.phoneNumberNotAssociated',
    ),
    [ErrorCode.InvitationTokenInvalidOrExpired]: TranslationService.translate(
      'toastMessages.invitationTokenInvalidOrExpired',
    ),
    [ErrorCode.AccountInvalidOrDeleted]: TranslationService.translate('toastMessages.accountInvalidOrDeleted'),
    [ErrorCode.BorrowerNotFound]: TranslationService.translate('toastMessages.borrowerNotFound'),
    [ErrorCode.AccountDoesNotExist]: TranslationService.translate('toastMessages.accountDoesNotExist'),
    [ErrorCode.ResetPasswordLinkNotConfigured]: TranslationService.translate(
      'toastMessages.resetPasswordLinkNotConfigured',
    ),
    [ErrorCode.AccountAlreadyExists]: TranslationService.translate('toastMessages.accountAlreadyExistsMessage'),
    [ErrorCode.IntermediaryNotFound]: TranslationService.translate('toastMessages.intermediaryNotFound'),
    [ErrorCode.ApplicationStatusNotProvided]: TranslationService.translate('toastMessages.applicationStatusMissing'),
    [ErrorCode.TeamMembersNotFound]: TranslationService.translate('toastMessages.teamMembersNotFound'),
    [ErrorCode.ProvidedLabelsNotFound]: TranslationService.translate('toastMessages.labelsNotFound'),
    [ErrorCode.BorrowerAndCoBorrowerCannotBeSame]: TranslationService.translate(
      'toastMessages.borrowerAndCoBorrowerSameError',
    ),
    [ErrorCode.CoBorrowerCannotBeSame]: TranslationService.translate('toastMessages.coBorrowerSameError'),
    [ErrorCode.BorrowerTypeNotAllowedForProduct]: TranslationService.translate('toastMessages.borrowerTypeNotAllowed'),
    [ErrorCode.BorrowerLockedForNewApplications]: TranslationService.translate('toastMessages.borrowerLockedError'),
    [ErrorCode.CannotAddLockedBorrowerToApplication]: TranslationService.translate(
      'toastMessages.lockedBorrowerAddError',
    ),
    [ErrorCode.PhoneNumberAlreadyInUse]: TranslationService.translate('toastMessages.phoneNumberAlreadyUsed'),
    [ErrorCode.CodeNotValid]: TranslationService.translate('toastMessages.codeInvalid'),
    [ErrorCode.UpdatePhoneNumberNotSupportedForMultiplePhoneNumbers]: TranslationService.translate(
      'toastMessages.updatePhoneNumberUnsupported',
    ),
    [ErrorCode.MfaCodeVerificationFailed]: TranslationService.translate('toastMessages.codeInvalid'),
    [ErrorCode.Unauthorized]: TranslationService.translate('toastMessages.tokenInvalidOrExpired'),
    [ErrorCode.CodeAlreadyWasSent]: TranslationService.translate('toastMessages.codeAlreadyWasSent', values),
  }[code]);

const isTranslatedErrorKey = (code: string): code is ErrorCode => {
  return Object.values(ErrorCode).includes(code as ErrorCode);
};

const extractValuesFromError = (error: RequestError) => {
  if (error.code === ErrorCode.CodeAlreadyWasSent) {
    const retryAfterSeconds = error.data?.retryAfterSeconds;

    return { seconds: retryAfterSeconds };
  }

  return {};
};

const getErrorMessageByStatusCode = (error: RequestError) => {
  if (error.responseStatus === HttpStatusCode.Unauthorized) {
    return getApiErrorMessages(ErrorCode.Unauthorized, {});
  }
};

const getApiErrorMessage = (error: RequestError) => {
  const errorByStatusCode = getErrorMessageByStatusCode(error);
  
  if (errorByStatusCode) {
    return errorByStatusCode;
  }

  const errorCode = error.code;

  if (!errorCode || !isTranslatedErrorKey(errorCode)) {
    return error.message;
  }

  const values = extractValuesFromError(error);

  return getApiErrorMessages(errorCode, values);
};

export default getApiErrorMessage;
