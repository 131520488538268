import { matchPath } from 'react-router-dom';
import { getBasicHeaders, getRequestErrorData, RequestBody, stringifyRequestBody } from 'utils/fetch';
import { RequestError } from 'errors/RequestError';
import TokenUtils from 'utils/TokenUtils';
import { AppRoute } from 'enums/AppRoute';
import { history } from 'routes/history';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import { BackendErrorType } from 'enums/BackendErrorType';
import { AppRouteWithParams } from 'enums/AppRouteWithParams';
import AppStorageKey from 'enums/AppStorageKey';
import AppHeaders from 'enums/AppHeaders';

export const AUTH_ROUTES = [
  AppRouteWithParams.CreateAccount,
  AppRouteWithParams.ResetPassword,
  AppRouteWithParams.CreateNewPassword,
  AppRouteWithParams.AcceptInvite,
  AppRouteWithParams.Start,
];

const FILENAME_FROM_HEADER_PATTERN = /filename="([^"]+)"/;

const BASE_NO_REDIRECT_ROUTES = [
  AppRouteWithParams.CreateAccount,
  AppRouteWithParams.CreateNewPassword,
  AppRouteWithParams.AcceptInvite,
  AppRouteWithParams.SignIn,
  AppRouteWithParams.Start,
];

export enum FetchMethodType {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface IFetchOptions {
  headers?: Headers;
  method?: FetchMethodType;
  body?: RequestBody;
  resetDefaultHeaders?: boolean;
  checkAuthorization?: boolean,
  responseType?: string;
  includePasswordValidationToken?: boolean;
}

let resignAccessTokenPromise: Promise<void> | null = null;

interface IEndpointConfiguration {
  path: string;
  method: FetchMethodType;
}

const NO_NEED_IN_RESIGN_TOKEN_ENDPOINTS: IEndpointConfiguration[]  = [
  { path: '/sessions', method: FetchMethodType.POST },
  { path: '/sessions', method: FetchMethodType.DELETE },
  { path: '/sessions', method: FetchMethodType.PUT },
];

export const BASE_API_PREFIX = '/api';

export const BACK_URL_QUERY_PARAM = 'back';

class BaseApi {
  constructor(
    protected unauthorizeRedirectUrl: AppRoute,
    protected noRedirectRoutes?: AppRoute[],
  ) {
  }

  protected async fetch<Body>(url: string, options?: IFetchOptions): Promise<Body> {
    const {
      headers: customHeaders,
      method = FetchMethodType.GET,
      body,
      resetDefaultHeaders,
      includePasswordValidationToken,
    } = options || {};

    const headers = resetDefaultHeaders ? new Headers() : getBasicHeaders();

    if (customHeaders) {
      customHeaders.forEach((value: string, header: string) => {
        headers.set(header, value);
      });
    }

    if (includePasswordValidationToken) {
      headers.set('passwordValidationToken', TokenUtils.getPasswordValidationToken() || '');
    }

    const adminAuthToken = window.sessionStorage.getItem(AppStorageKey.AdminAuthToken);

    if (adminAuthToken) {
      headers.set(AppHeaders.AdminToken, adminAuthToken);
    }

    if (resignAccessTokenPromise) {
      await resignAccessTokenPromise;
    }

    const accessToken = TokenUtils.getAccessToken();

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    const response = await fetch(`${BASE_API_PREFIX}${url}`, {
      method,
      headers,
      body: stringifyRequestBody(body),
    });

    return this.processResponse(response, url, headers, options);
  }

  protected async download(url: string) {
    const response = await this.fetch<Response>(url, {
      responseType: 'blob',
    });

    const [, filename] = response.headers.get('Content-Disposition')?.match(FILENAME_FROM_HEADER_PATTERN) || [];

    return {
      file: await response.blob(),
      filename,
    };
  }

  private async processResponse(response: Response, url: string, headers: Headers, options?: IFetchOptions) {
    if (response.ok) {
      if (options?.responseType === 'blob') {
        return response;
      }

      return response.json();
    }

    return this.handleResponseError(response, url, headers, options);
  }

  private async handleResponseError(response: Response, url: string, headers: Headers, options?: IFetchOptions) {
    const { message, type, code, data } = await getRequestErrorData(response);

    if (response.status === HttpStatusCode.Unauthorized && type === BackendErrorType.PasswordTokenRequired) {
      return this.redirectWithBackUrl(AppRoute.AdminPasswordRequired, [AppRoute.AdminPasswordRequired]);
    }

    const requestDoesNotRequireResignToken = NO_NEED_IN_RESIGN_TOKEN_ENDPOINTS.some(({ path, method }) => {
      return url === path && options?.method === method;
    });

    if (response.status === HttpStatusCode.Unauthorized && !requestDoesNotRequireResignToken) {
      if (!resignAccessTokenPromise && this.isRequestTokenEqualToCurrentToken(headers)) {
        resignAccessTokenPromise = this.resignAccessToken();
        await resignAccessTokenPromise;
        resignAccessTokenPromise = null;
      }

      if (TokenUtils.getRefreshToken()) {
        return this.fetch(url, options);
      }
    }

    if (response.status === HttpStatusCode.Forbidden && type === BackendErrorType.AccessRevoked) {
      return history.push(AppRoute.Forbidden);
    }

    throw new RequestError(message, response.status, code, data);
  }

  private async resignAccessToken() {
    // We use fetch, not this.fetch to prevent cycles
    const headers = getBasicHeaders();

    headers.set('accountRefreshToken', TokenUtils.getRefreshToken() || '');

    const adminAuthToken = window.sessionStorage.getItem(AppStorageKey.AdminAuthToken);

    if (adminAuthToken) {
      headers.set(AppHeaders.AdminToken, adminAuthToken);
    }

    const response = await fetch('/api/sessions', {
      method: FetchMethodType.PUT,
      headers,
    });

    if (response.status === HttpStatusCode.Unauthorized) {
      TokenUtils.removeTokens();

      if (!this.isNoRedirectRoute()) {
        this.redirectWithBackUrl(this.unauthorizeRedirectUrl, AUTH_ROUTES);
      }

      return;
    }

    const { accessToken, refreshToken } = await response.json();
    TokenUtils.setTokens({ accessToken, refreshToken });
  };

  private isRequestTokenEqualToCurrentToken(headers: Headers) {
    return !TokenUtils.getAccessToken() || headers.get('authorization') === `Bearer ${TokenUtils.getAccessToken()}`;
  }

  private isNoRedirectRoute() {
    return [...BASE_NO_REDIRECT_ROUTES, ...(this.noRedirectRoutes || [])].some(route => matchPath({ path: route }, history.location.pathname));
  }

  private redirectWithBackUrl(path: string, whitelistRoutes: string[] = []) {
    const backUrl = this.getBackUrl(whitelistRoutes);

    return backUrl
      ? history.push(`${path}?${BACK_URL_QUERY_PARAM}=${encodeURIComponent(backUrl)}`)
      : history.push(path);
  }

  private getBackUrl(whitelistRoutes: string[]) {
    const queryParams = new URLSearchParams(history.location.search);

    const backUrl = queryParams.get(BACK_URL_QUERY_PARAM);

    if (backUrl) {
      return whitelistRoutes.includes(backUrl) ? undefined : backUrl;
    }

    const currentPath = history.location.pathname + history.location.search;

    if (!currentPath || currentPath === '/') {
      return undefined;
    }

    return !matchPath(AppRouteWithParams.AdminPasswordRequired, currentPath)
      && !whitelistRoutes.includes(currentPath)
        ? currentPath
        : undefined;
  }

  protected get absoluteOrigin(): string {
    // TODO: try to remove port comparing
    const port = !window.location.port || window.location.port === '80' || window.location.port === '443'
      ? ''
      : `:${window.location.port}`;

    return `${window.location.protocol}//${window.location.hostname}${port}`;
  }
}

export default BaseApi;
