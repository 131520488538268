import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useGetPortalSettingsQuery } from 'slices';

const CustomCssConfigProvider: React.FC = ({ children }) => {
  const { currentData: settings } = useGetPortalSettingsQuery();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <style>{settings?.customCssConfig.stylesheet}</style>
        </Helmet>
      </HelmetProvider>
      {children}
    </>
  );
};

export default CustomCssConfigProvider;
