import { Variable } from 'product_modules/api/Core/VariablesApi';
import createEntitiesLoader from 'product_modules/providers/createEntitiesLoaderProvider';
import { recomputeUnknownVariablesFor, saveVariables } from 'handlers/variablesSlice';
import { IReduxState } from 'types/ReduxState';
import { variablesApi } from 'store/api';

const [
  VariablesBySystemNameLoaderContext,
  VariablesBySystemNameLoader,
] = createEntitiesLoader<Variable, string>({
  isEntityLoaded: (field: string, state: IReduxState) => !!state.variables.variablesBySystemName[field],
  searchEntities: async (systemNames: string[]) => {
    const { items } = await variablesApi.search({
      systemNames,
      showArchived: true,
    }, undefined);

    return items;
  },
  createRecomputeUnknownEntitiesForAction: recomputeUnknownVariablesFor,
  createSaveEntitiesAction: saveVariables,
});

export {
  VariablesBySystemNameLoaderContext,
  VariablesBySystemNameLoader,
};
