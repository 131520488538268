import { BorrowerType } from 'product_modules/enums/BorrowerType';
import BaseInvitesRestApi, {
  IBaseInviteInfo, IBaseInvitesApi,
} from 'api/digifi/base/BaseInvitesApi';

export interface IBorrowerInviteInfo extends IBaseInviteInfo {
  firstName: string;
  lastName: string;
  companyName: string;
  borrowerType: BorrowerType;
}

export interface IBorrowerInvitesApi extends IBaseInvitesApi {
  getInviteInfo(token: string): Promise<IBorrowerInviteInfo>;
}

export default class BorrowerInvitesApi extends BaseInvitesRestApi implements IBorrowerInvitesApi {
  public getInviteInfo(token: string): Promise<IBorrowerInviteInfo> {
    return this.fetch(`/${this.resourceName}/${token}`);
  }
}
