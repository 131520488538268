import React, { createContext, ReactNode, useCallback, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { batch, useDispatch, useStore } from 'react-redux';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'store';
import PromiseQueue from 'product_modules/utils/PromiseQueue';

interface CreateEntitiesLoaderParams<Entity, Field> {
  isEntityLoaded: (field: Field, state: RootState) => boolean;
  searchEntities: (fields: Field[], signal: AbortSignal) => Promise<Entity[]>;
  createRecomputeUnknownEntitiesForAction: (fields: Field[]) => AnyAction;
  createSaveEntitiesAction?: (entities: Entity[]) => AnyAction;
  saveEntities?: (entities: Entity[], dispatch: Dispatch<AnyAction>) => void;
}

export interface EntitiesLoaderProps {
  debounceTimeout?: number;
  children?: ReactNode;
}

export interface EntitiesLoader<Field extends string | number> {
  load: (fields: Field[], requestId: string) => Promise<void>;
  abort: (requestId: string) => void;
}

const PROMISE_QUEUE_MAX_LENGTH = 1;

const createEntitiesLoader = <
  Entity,
  Field extends string | number,
>(params: CreateEntitiesLoaderParams<Entity, Field>) => {
  const EntitiesLoaderContext = createContext<
    EntitiesLoader<Field> | null
  >(null);

  const EntitiesLoader = ({
    children,
    debounceTimeout,
  }: EntitiesLoaderProps) => {
    const dispatch = useDispatch();
    const store = useStore<RootState>();

    const pendingRequestsMapRef = useRef(new Map<string, Field[]>());
    const progressRequestsSetRef = useRef(new Set<string>());
    const abortControllerRef = useRef<AbortController | null>(new AbortController());

    const abort = useCallback((requestId: string) => {
      pendingRequestsMapRef.current.delete(requestId);

      if (!progressRequestsSetRef.current.has(requestId)) {
        return;
      }

      progressRequestsSetRef.current.delete(requestId);

      if (progressRequestsSetRef.current.size === 0) {
        abortControllerRef.current?.abort();
      }
    }, [pendingRequestsMapRef, progressRequestsSetRef]);

    const loadEntities = useMemo(() => {
      const searchQueue = new PromiseQueue(PROMISE_QUEUE_MAX_LENGTH);

      const performSearch = async () => {
        try {
          const state = store.getState();

          const fields: Field[] = [];
          const visitedFields = new Set<Field>();

          for (const [requestId, requestedFields] of pendingRequestsMapRef.current) {
            requestedFields.forEach((field) => {
              if (visitedFields.has(field)) {
                return;
              }

              visitedFields.add(field);

              if (!params.isEntityLoaded(field, state)) {
                fields.push(field);
              }
            });

            progressRequestsSetRef.current.add(requestId);
          }

          abortControllerRef.current = new AbortController();
          pendingRequestsMapRef.current.clear();

          if (fields.length === 0) {
            return;
          }

          const entities = await params.searchEntities(fields, abortControllerRef.current.signal);

          if (abortControllerRef.current.signal.aborted) {
            return;
          }

          progressRequestsSetRef.current.clear();

          batch(() => {
            // The order of actions is important here.
            if (params.saveEntities) {
              params.saveEntities(entities, dispatch);
            }

            if (params.createSaveEntitiesAction) {
              dispatch(params.createSaveEntitiesAction(entities));
            }

            dispatch(params.createRecomputeUnknownEntitiesForAction(fields));
          });
        } finally {
          abortControllerRef.current = null;
        }
      };

      const debouncedSearchEntities = debounce(async () => {
        searchQueue.enqueue(() => performSearch());
      }, debounceTimeout);

      return async (requestedFields: Field[], requestId: string) => {
        pendingRequestsMapRef.current.set(requestId, requestedFields);

        await debouncedSearchEntities();
      };
    }, [debounceTimeout, store]);

    const loader = useMemo(() => ({
      load: loadEntities,
      abort,
    }), [loadEntities]);

    return (
      <EntitiesLoaderContext.Provider value={loader}>
        {children}
      </EntitiesLoaderContext.Provider>
    );
  };

  return [
    EntitiesLoaderContext,
    EntitiesLoader,
  ] as [typeof EntitiesLoaderContext, typeof EntitiesLoader];
};

export default createEntitiesLoader;
