import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISignInParams } from 'api/digifi/SessionsApi';
import { sessionsApi } from 'store/api';
import TokenUtils from 'utils/TokenUtils';
import { apiSlice, ApiSliceTagType } from './apiSlice';
import { createSafeQueryFn } from './utils/queries';
import { getItemInvalidationTag } from './utils/tags';

export const logout = createAsyncThunk(
  'auth/logout',
  async () => {
    await sessionsApi.logout();
    TokenUtils.removeTokens();
  },
);

export const sessionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<void, ISignInParams>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async (params) => {

        const { accessToken, refreshToken } = await sessionsApi.signIn(params);
        TokenUtils.setTokens({ accessToken, refreshToken });
      }),
    }),
  }),
});

export const { useSignInMutation } = sessionsSlice;
