import BaseApi, { FetchMethodType } from 'api/BaseApi';

export interface IBrowserInfo {
  name: string;
  os: string;
  type: string;
  version: string;
}

export interface ILoggerApi {
  log(messages: string[], browserInfo: IBrowserInfo): Promise<void>;
}

export default class LoggerApi extends BaseApi implements ILoggerApi {
  public async log(messages: string[], browserInfo: IBrowserInfo): Promise<void> {
    await this.fetch('/logs', {
      method: FetchMethodType.POST,
      body: {
        messages,
        browserInfo,
      },
    });
  }
}
