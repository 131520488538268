import { createElement, lazy, Suspense } from 'react';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import PortalType from 'enums/PortalType';

const PORTAL_TYPE = process.env.REACT_APP_PORTAL_TYPE?.toLowerCase();

const Routers = {
  [PortalType.Borrower]: lazy(() => import('./borrower-router')),
  [PortalType.Intermediary]: lazy(() => import('./intermediary-router')),
  default: () => 'REACT_APP_PORTAL_TYPE is invalid',
};

const EntryRouter = () => {
  return (
    <Suspense fallback={<LoaderOverlay />}>
      {createElement(Routers[PORTAL_TYPE as PortalType] || Routers.default)}
    </Suspense>
  );
};

export default EntryRouter;
