import BaseInvitesRestApi, {
  IBaseInviteInfo,
  IBaseInvitesApi,
} from 'api/digifi/base/BaseInvitesApi';

export interface IIntermediaryInviteInfo extends IBaseInviteInfo {
  fullName: string;
}

export interface IIntermediaryInvitesApi extends IBaseInvitesApi {
  getInviteInfo(token: string): Promise<IIntermediaryInviteInfo>;
}

export default class IntermediaryInvitesApi extends BaseInvitesRestApi implements IIntermediaryInvitesApi {
  public getInviteInfo(token: string): Promise<IIntermediaryInviteInfo> {
    return this.fetch(`/${this.resourceName}/${token}`);
  }
}
