import BaseAccountsApi, {
  IBaseAccountsApi,
  IBaseAccountParams,
} from 'api/digifi/base/BaseAccountsApi';
import { FetchMethodType } from 'api/BaseApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { IAuthResponse } from 'api/digifi/types';

export interface ICreateBorrowerAccountParams {
  email: string;
  phoneNumber: string;
  password: string;
  borrowerType: BorrowerType;
  firstName?: string;
  lastName?: string;
  companyName?: string;
}

export interface ICurrentBorrowerAccountParams extends IBaseAccountParams {
  borrowerId: string;
}

export interface IBorrowerAccountsApi extends IBaseAccountsApi {
  createAccount(body: ICreateBorrowerAccountParams): Promise<IAuthResponse>;
  getCurrentAccount(): Promise<ICurrentBorrowerAccountParams>;
}

export default class BorrowerAccountsApi extends BaseAccountsApi implements IBorrowerAccountsApi {
  public createAccount(body: ICreateBorrowerAccountParams): Promise<IAuthResponse> {
    return this.fetch(`/${this.resourceName}`, {
      method: FetchMethodType.POST,
      body,
    });
  };

  public getCurrentAccount(): Promise<ICurrentBorrowerAccountParams> {
    return this.fetch(`/${this.resourceName}`);
  }
}
