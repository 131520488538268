import { environmentApi } from 'store/api';
import DigitalLendingPortalEnvironment from 'enums/DigitalLendingPortalEnvironment';
import { apiSlice } from './apiSlice';

export const portalEnvironmentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortalEnvironment: builder.query<
      DigitalLendingPortalEnvironment,
      void
    >({
      queryFn: async () => {
        try {
          const result = await environmentApi.getEnvironment();

          return { data: result };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetPortalEnvironmentQuery } = portalEnvironmentSlice;
