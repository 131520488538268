import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RequestError } from 'errors/RequestError';
import getApiErrorMessage from './getApiErrorMessage';

const rejectWithValue = (
  error: Error,
  thunkApi: BaseThunkAPI<unknown, unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown, unknown, any>,
) => {
  if (error instanceof RequestError) {
    return thunkApi.rejectWithValue({
      responseStatus: error.responseStatus,
      message: getApiErrorMessage(error),
      code: error.code,
    });
  }

  throw error;
};

export default rejectWithValue;
