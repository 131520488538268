import BaseApi, { FetchMethodType } from 'api/BaseApi';

interface ICreatePasswordValidationTokenResponse {
  passwordValidationToken: string;
}

export interface IBaseAccountParams {
  email: string;
  phone: string;
  isEmailVerified: boolean;
  isMfaComplete: boolean;
  isPhoneVerified: boolean;
  fullName: string;
  isBlocked: boolean;
}

export interface IUpdatePasswordParams {
  oldPassword: string;
  newPassword: string;
}

export interface IBaseAccountsApi {
  createPasswordValidationToken(password: string): Promise<ICreatePasswordValidationTokenResponse>;
  sendUpdatePhoneNumberCode(phone: string): Promise<void>;
  updatePhoneNumber(code: string): Promise<void>;
  sendUpdateEmailAddressCode(email: string): Promise<void>;
  updateEmailAddress(code: string): Promise<void>;
  updatePassword(params: IUpdatePasswordParams): Promise<void>;
}

export default abstract class BaseAccountsApi extends BaseApi implements IBaseAccountsApi {
  protected resourceName = 'accounts';

  public createPasswordValidationToken(password: string): Promise<ICreatePasswordValidationTokenResponse> {
    return this.fetch(`/${this.resourceName}/password-validation-token`, {
      method: FetchMethodType.POST,
      body: {
        password,
      },
    });
  };

  public sendUpdatePhoneNumberCode(phone: string): Promise<void> {
    return this.fetch(`/${this.resourceName}/phone`, {
      method: FetchMethodType.PUT,
      body: {
        phone,
      },
      includePasswordValidationToken: true,
    });
  }

  public updatePhoneNumber(code: string): Promise<void> {
    return this.fetch(`/${this.resourceName}/phone/${code}`, {
      method: FetchMethodType.PUT,
    });
  }

  public sendUpdateEmailAddressCode(email: string): Promise<void> {
    return this.fetch(`/${this.resourceName}/email`, {
      method: FetchMethodType.PUT,
      body: {
        email,
      },
      includePasswordValidationToken: true,
    });
  }

  public updateEmailAddress(code: string): Promise<void> {
    return this.fetch(`/${this.resourceName}/email/${code}`, {
      method: FetchMethodType.PUT,
      body: {
        code,
      },
    });
  }

  public updatePassword({ oldPassword, newPassword }: IUpdatePasswordParams): Promise<void> {
    return this.fetch(`/${this.resourceName}/password`, {
      method: FetchMethodType.PUT,
      body: {
        oldPassword,
        newPassword,
      },
    });
  }
}
