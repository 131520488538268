export class RequestError extends Error {
  constructor(
    message: string,
    readonly responseStatus: number,
    readonly code?: string,
    readonly data?: Record<string, any>,
  ) {
    super(message);
  }
}
