import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice, EntityState,
} from '@reduxjs/toolkit';
import {
  IApplicationDocument,
  IBatchUploadDocumentParams,
} from 'api/digifi/DocumentsApi';
import TranslationService from 'product_modules/services/TranslationService';
import { downloadBlobFile } from 'product_modules/utils/downloadBlobFile';
import { RootState } from 'store';
import { documentsApi } from 'store/api';
import rejectWithValue from 'utils/rejectWithValue';

enum ApplicationDocumentsActionType {
  GetApplicationDocuments = 'applicationDocuments/getApplicationDocuments',
  BatchUploadApplicationDocuments = 'applicationDocuments/batchUploadApplicationDocuments',
  DownloadApplicationDocument = 'applicationDocuments/downloadApplicationDocument',
  DownloadArchiveOfApplicationDocuments = 'applicationDocuments/downloadArchiveOfApplicationDocuments',
}

export interface IApplicationDocumentsState extends EntityState<IApplicationDocument> {
  documentDownloadingState: Record<string, string>;
}

const documentsAdapter = createEntityAdapter<IApplicationDocument>();

const initialState: IApplicationDocumentsState = documentsAdapter.getInitialState({
  documentDownloadingState: {},
});

export const getApplicationDocuments = createAsyncThunk(
  ApplicationDocumentsActionType.GetApplicationDocuments,
  async({ applicationId }: { applicationId: string }, thunkApi) => {
    try {
      const { documents } = await documentsApi.getApplicationDocuments(applicationId);

      return documents;
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
});

export const downloadApplicationDocument = createAsyncThunk(
  ApplicationDocumentsActionType.DownloadApplicationDocument, async (documentId: string, thunkApi) => {
  try {
    const response = await documentsApi.download(documentId);

    downloadBlobFile(response);
  } catch (error) {
    return rejectWithValue(error, thunkApi);
  }
});

interface IDownloadAllDocumentsActionParams {
  applicationId: string;
  displayId: string;
  id: string;
}

export const downloadAllDocuments = createAsyncThunk(
  ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocuments,
  async (params: IDownloadAllDocumentsActionParams, thunkApi) => {
    try {
      const response = await documentsApi.downloadAll(params.applicationId);

      downloadBlobFile({
        ...response,
        filename: `${TranslationService.translate('applicationDetails.applicationDocuments')} - ${params.displayId}`, // Change to DisplayId
      });
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const batchUploadApplicationDocuments = createAsyncThunk<void, { applicationId: string; params: IBatchUploadDocumentParams[] }>(
  ApplicationDocumentsActionType.BatchUploadApplicationDocuments,
  async ({ applicationId, params }) => {
    await documentsApi.batchUpload(applicationId, params);
  },
);

const applicationDocumentsSlice = createSlice({
  name: 'applicationDocumentsSlice',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getApplicationDocuments.fulfilled, (state, { payload }) => {
        state.documentDownloadingState = {};
        documentsAdapter.setAll(state, payload);
      })
      .addCase(downloadApplicationDocument.pending, (state, action) => {
        state.documentDownloadingState[action.meta.arg] = 'loading';
      })
      .addCase(downloadAllDocuments.pending, (state, action) => {
        state.documentDownloadingState[action.meta.arg.id] = 'loading';
      })
      .addCase(downloadApplicationDocument.fulfilled, (state, action) => {
        state.documentDownloadingState[action.meta.arg] = 'success';
      })
      .addCase(downloadAllDocuments.fulfilled, (state, action) => {
        state.documentDownloadingState[action.meta.arg.id] = 'success';
      })
      .addCase(downloadAllDocuments.rejected, (state, action) => {
        state.documentDownloadingState[action.meta.arg.id] = 'failure';
      })
      .addCase(downloadApplicationDocument.rejected, (state, action) => {
        state.documentDownloadingState[action.meta.arg] = 'failure';
      });
  },
});

export const { selectEntities: selectDocumentEntities } = documentsAdapter.getSelectors((state: RootState) => state.applicationDocuments);

export default applicationDocumentsSlice.reducer;
